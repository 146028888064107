import { StrapiTextualProps } from '@/features/strapi/components/textual';
import { StrapiPartnerReviewCardProps } from '@/features/strapi/components/partner-review-card';
// @ts-ignore
import x1 from '../assets/x1.svg';
// @ts-ignore
import x2 from '../assets/x2.svg';
// @ts-ignore
import x3 from '../assets/x3.svg';
// @ts-ignore
import x4 from '../assets/x4.svg';
// @ts-ignore
import x5 from '../assets/x5.svg';
// @ts-ignore
import x6 from '../assets/x6.svg';
// @ts-ignore
import partnerImage from '../assets/partners/carolinImage.png';
// @ts-ignore
import verbalImage from '../assets/partners/verbalvisual.png';
// @ts-ignore
import visuallyImage from '../assets/vilogo.png';
// @ts-ignore
import shopliftImage from '../assets/Bitmap4.png';

type MediaType = 'image' | 'video';

interface ComparisonEasyOfUseItem {
  media: {
    type: MediaType;
    src: string;
  };
  text: StrapiTextualProps;
}

interface ComparisonByDataContentItem {
  exampleA: string;
  exampleB: string;
}

interface ComparisonByFeatureContentItem {
  exampleA: boolean | string;
  exampleB: boolean | string;
}

interface ComparisonByDataHeaders {
  text: string;
  styles: StrapiTextualProps;
}

export interface ComparisonPageContent {
  ComparisonByDataHeaders: ComparisonByDataHeaders[];
  ComparisonByDataContent: {
    content: Record<string, ComparisonByDataContentItem>;
  };
  ComparisonProductsAndFeaturesSection: {
    title: StrapiTextualProps;
  };
  ComparisonByFeatureHeaders: ComparisonByDataHeaders[];
  ComparisonByFeatureContent: {
    features: Record<string, ComparisonByFeatureContentItem>;
  };
  ComparisonEasyOfUse: ComparisonEasyOfUseItem[];
  ComparisonEasyOfUseSecrionTitle: {
    title: StrapiTextualProps;
    subTitle: StrapiTextualProps;
  };
  ComparisonSectionHeader: {
    title: StrapiTextualProps;
  };
}

export const ComparisonNavChips = {
  mobile: [
    {
      text: `Visually VS Dynamic Yield`,
      href: `/public/comparison/dy`,
    },
    {
      text: `Visually VS Rebuy`,
      href: `/public/comparison/rebuy`,
    },
    {
      text: `Visually VS Intelligems`,
      href: `/public/comparison/intelligems`,
    },
    {
      text: `Visually VS Shopift`,
      href: `/public/comparison/shoplift`,
    },
  ],
  desktop: [
    {
      text: `Visually VS Dynamic Yield`,
      href: `/public/comparison/dy`,
    },
    {
      text: `Visually VS Rebuy`,
      href: `/public/comparison/rebuy`,
    },
    {
      text: `Visually VS Intelligems`,
      href: `/public/comparison/intelligems`,
    },
    {
      text: `Visually VS Shopift`,
      href: `/public/comparison/shoplift`,
    },
    {
      text: `Partners`,
      href: `/public/partners`,
    },
    {
      text: `Pricing`,
      href: `/pricing`,
    },
  ],
  styles: {
    text: {
      size: `1.5rem`,
      weight: `normal`,
      lineHeight: `1.8`,
      mobileMultiplier: 0.75,
    } as StrapiTextualProps,
  },
};

export const ComparisonWhyUsContent = {
  title: {
    text: `Visually is the only CRO consolidated platform for full-funnel optimization:`,
    weight: `bold`,
    size: `2.4rem`,
    mobileMultiplier: 0.7,
    mobileText: `Visually is the only CRO consolidated\nplatform for full-funnel optimization:`,
    highlight: `full-funnel`,
    style: { textAlign: `center` },
    highlightStyle: { color: `#3A47FE` },
  } as StrapiTextualProps,
};

export const ComparisonWhyUsFeatures = [
  {
    text: {
      text: `Personalization & Behavioral\nTargeting`,
      weight: `normal`,
      size: `2rem`,
      lineHeight: `1.8`,
      mobileMultiplier: 0.67,
      mobileText: `Personalization &\nBehavioral Targeting`,
      style: { textAlign: `left` },
    } as StrapiTextualProps,
    image: x1,
  },
  {
    text: {
      text: `Content and site-wide A/B\ntesting`,
      weight: `normal`,
      size: `2rem`,
      lineHeight: `1.8`,
      mobileMultiplier: 0.67,
      mobileText: `Content and site\nwide A/B testing`,
      style: { textAlign: `left` },
    } as StrapiTextualProps,
    image: x4,
  },
  {
    text: {
      text: `Price and checkout A/B testing`,
      weight: `normal`,
      size: `2rem`,
      lineHeight: `1.8`,
      mobileMultiplier: 0.67,
      mobileText: `Price and checkout\nA/B testing`,
      style: { textAlign: `left` },
    } as StrapiTextualProps,
    image: x2,
  },
  {
    text: {
      text: `Product Upsells`,
      weight: `normal`,
      size: `2rem`,
      lineHeight: `1.8`,
      mobileMultiplier: 0.67,
      mobileText: `Product Upsells`,
    } as StrapiTextualProps,
    image: x5,
  },
  {
    text: {
      text: `Post-purchase upsells`,
      weight: `normal`,
      size: `2rem`,
      lineHeight: `1.8`,
      mobileMultiplier: 0.67,
      mobileText: `Post-purchase\nupsells`,
      style: { textAlign: `left` },
    } as StrapiTextualProps,
    image: x3,
  },
  {
    text: {
      text: `Product Recommendations`,
      weight: `normal`,
      size: `2rem`,
      lineHeight: `1.8`,
      mobileMultiplier: 0.67,
      mobileText: `Product\nRecommendations`,
      style: { textAlign: `left` },
    } as StrapiTextualProps,
    image: x6,
  },
];

export const ComparisonTableTextStyles = {
  title: {
    size: `2.6rem`,
    weight: `normal`,
    lineHeight: `1.8`,
    mobileMultiplier: 0.5,
  } as StrapiTextualProps,
  columnTitle: {
    size: `2.6rem`,
    weight: `normal`,
    lineHeight: `1.2`,
    mobileMultiplier: 0.5,
  } as StrapiTextualProps,
  feature: {
    size: `2.6rem`,
    weight: `normal`,
    lineHeight: `1.8`,
    mobileMultiplier: 0.55,
  } as StrapiTextualProps,
  contentText: {
    size: `2.6rem`,
    weight: `normal`,
    lineHeight: `1.8`,
    mobileMultiplier: 0.55,
    style: {
      textAlign: `left`,
      fontWeight: `300`,
    },
  } as StrapiTextualProps,
  contentTextMobile: {
    size: `2.6rem`,
    weight: `normal`,
    lineHeight: `1.4`,
    mobileMultiplier: 0.55,
    style: {
      textAlign: `left`,
    },
  } as StrapiTextualProps,
  featureInCell: {
    size: `2.6rem`,
    weight: `bold`,
    lineHeight: `1.8`,
    mobileMultiplier: 0.55,
  } as StrapiTextualProps,
};

export const ComparisonHeaderContent = {
  title: {
    size: `5.8rem`,
    weight: `bold`,
    text: `Visually.io`,
    lineHeight: `1.3`,
    mobileMultiplier: 0.68,
    mobileText: `Visually.io`,
    smMobileMultiplier: 0.58,
  } as StrapiTextualProps,
  secondTitle: {
    size: `4.2rem`,
    weight: `bold`,
    text: `Shoplift`,
    lineHeight: `1.3`,
    mobileMultiplier: 0.65,
    mobileText: `Shoplift`,
  } as StrapiTextualProps,
  subTitle: {
    size: `2.4rem`,
    weight: `normal`,
    lineHeight: `1.8`,
    text: `Visually, the Shopify No-code CRO Platform,\nThe Path to profitability starts with optimization.`,
    style: { textAlign: `center` },
    mobileMultiplier: 0.7,
    highlight: `optimization`,
    highlightStyle: { fontWeight: `bold` },
  } as StrapiTextualProps,
  mobileSubTitle: {
    size: `2.4rem`,
    weight: `normal`,
    lineHeight: `1.8`,
    text: `Visually, the Shopify No-code CRO\nPlatform, The Path to profitability starts\n with optimization.`,
    mobileText: `Visually, the Shopify No-code CRO\nPlatform, The Path to profitability starts\n with optimization.`,
    style: { textAlign: `center` },
    mobileMultiplier: 0.7,
  } as StrapiTextualProps,
  secondSubTitle: {
    text: `A No-code CRO (Conversion Rate Optimization) platform: sophisticated, user-friendly, and empowering you
    to optimize and boost your bottom line effortlessly.`,
    size: `2rem`,
    weight: `normal`,
    lineHeight: `1.8`,
    mobileText: `A No-code CRO (Conversion Rate Optimization) platform:
    sophisticated, user-friendly, and empowering you to optimize
    and boost your bottom line effortlessly.`,
    mobileMultiplier: 0.65,
  } as StrapiTextualProps,
  cta: {
    size: `2.2rem!important`,
    weight: `normal`,
    text: `Become a partner`,
    className: `btn primary-btn small`,
  } as StrapiTextualProps,
  review: {
    image: partnerImage,
    logo: verbalImage,
    name: `Caroline Dau`,
    job: `Senior Director Program
Management at`,
    text: `"Visually's platform is phenomenal in the way it has
allowed us to genuinely grow our client's businesses
through building smarter shopping experiences
with continuous testing and optimization. The ease
of use and the Visually intuitive editor cut us
significant go-live time"`,
  } as StrapiPartnerReviewCardProps,
  visuallyLogo: visuallyImage,
  otherCompanyLogo: shopliftImage,
};

export const ComparisonPageContent = {
  ComparisonByDataContent: {
    content: {
      'In short': {
        exampleA: `Full optimization platform\nA/B test, Personalization, Product recs,\nupsells, post-purchase upsells`,
        exampleB: `A/B test tool, theme\ntesting, personalization`,
      },
      'Free trial?': {
        exampleA: `14 days`,
        exampleB: `14 days`,
      },
      Price: {
        exampleA: `Starting at $19\nincluding all feature sets`,
        exampleB: `Starting at $99\n($74 for yearly plan)\nfor A/B test only`,
      },
      'Price structure': {
        exampleA: `Based on order volume`,
        exampleB: `Based on store visitors`,
      },
      'Site speed': {
        exampleA: `No flickering, No affect on site speed`,
        exampleB: `Redirect method\ncreates an additional page load`,
      },
    },
  },

  ComparisonByFeatureContent: {
    features: {
      'Visual editor': {
        exampleA: true,
        exampleB: false,
      },
      'No-code platform': {
        exampleA: true,
        exampleB: true,
      },
      Personalization: {
        exampleA: true,
        exampleB: true,
      },
      'Behavioral targeting': {
        exampleA: true,
        exampleB: true,
      },
      'Product recommendations': {
        exampleA: true,
        exampleB: false,
      },
      Upsells: {
        exampleA: true,
        exampleB: false,
      },
      'Post-purchase upsells': {
        exampleA: true,
        exampleB: false,
      },
      'Integration with your tech stack': {
        exampleA: true,
        exampleB: true,
      },
      'Site-wide A/B testing': {
        exampleA: true,
        exampleB: true,
      },
      'Theme testing': {
        exampleA: true,
        exampleB: true,
      },
      'Content testing': {
        exampleA: true,
        exampleB: true,
      },
      'Image testing': {
        exampleA: true,
        exampleB: true,
      },
      'Checkout extensibility: a/b testing supported': {
        exampleA: true,
        exampleB: false,
      },
      'Third party apps testing': {
        exampleA: true,
        exampleB: false,
      },
      'Product Price Testing': {
        exampleA: true,
        exampleB: false,
      },
      'Shipping Price Testing': {
        exampleA: true,
        exampleB: false,
      },

      'GA4 integration': {
        exampleA: true,
        exampleB: `Not out of the box`,
      },
      'Multiple variation testing': {
        exampleA: `Unlimited variations`,
        exampleB: false,
      },
      'Custom Triggers': {
        exampleA: true,
        exampleB: false,
      },
    },
  },

  ComparisonByDataHeaders: [
    { text: ``, styles: ComparisonTableTextStyles.title },
    { text: `Visually.io`, styles: ComparisonTableTextStyles.columnTitle },
    { text: `Shoplift`, styles: ComparisonTableTextStyles.columnTitle },
  ],

  ComparisonByFeatureHeaders: [
    { text: `Features`, styles: ComparisonTableTextStyles.title },
    { text: `Visually.io`, styles: ComparisonTableTextStyles.columnTitle },
    { text: `Shoplift`, styles: ComparisonTableTextStyles.columnTitle },
  ],

  ComparisonEasyOfUse: [
    {
      media: {
        type: `video` as MediaType,
        src: `https://iframe.mediadelivery.net/embed/162751/e15b1776-d4f0-40af-8d13-8424070561c3`,
      },
      text: {
        text: `Effortlessly Execute A/B\nTesting in Just a Few\nSeconds`,
        size: `3.5rem`,
        lineHeight: `1.6`,
        mobileMultiplier: 0.4,
        style: {
          fontWeight: `300`,
        },
      } as StrapiTextualProps,
    },
    {
      media: {
        type: `video` as MediaType,
        src: `https://iframe.mediadelivery.net/embed/162751/e15b1776-d4f0-40af-8d13-8424070561c3`,
      },
      text: {
        text: `Configuring a smart\nupselling algorithm has\nnever been easier`,
        size: `3.5rem`,
        lineHeight: `1.6`,
        mobileMultiplier: 0.4,
        style: {
          fontWeight: `300`,
        },
      } as StrapiTextualProps,
    },
    {
      media: {
        type: `video` as MediaType,
        src: `https://iframe.mediadelivery.net/embed/162751/e15b1776-d4f0-40af-8d13-8424070561c3`,
      },
      text: {
        text: `Quickly personalize\nexisting pages, apps, and\nworkflows.`,
        size: `3.5rem`,
        lineHeight: `1.6`,
        mobileMultiplier: 0.4,
        style: {
          fontWeight: `300`,
        },
      } as StrapiTextualProps,
    },
  ],

  ComparisonProductsAndFeaturesSection: {
    title: {
      text: `Product & Features`,
      weight: `bold`,
      size: `4.7rem`,
      mobileMultiplier: 0.55,
    } as StrapiTextualProps,
  },

  ComparisonEasyOfUseSecrionTitle: {
    title: {
      text: `Ease Of Use`,
      weight: `bold`,
      size: `5.7rem`,
      mobileMultiplier: 0.45,
    } as StrapiTextualProps,

    subTitle: {
      text: `Marketing teams worldwide turn to Visually.io everyday. It's also the platform of
    choice for imaginative individuals like you. Here's what users have to say`,
      size: `2.9rem`,
      lineHeight: `1.35`,
      mobileMultiplier: 0.95,
    } as StrapiTextualProps,
  },

  ComparisonSectionHeader: {
    title: {
      text: `How does Visually compare with Shoplift`,
      weight: `bold`,
      size: `4.7rem`,
      mobileMultiplier: 0.5,
      mobileText: `How does Visually compare
    with Shoplift`,
    } as StrapiTextualProps,
  },
};

export const getVisuallyBtn = {
  text: `Try Visually for Free`,
  size: `2.2rem`,
  weight: `normal`,
  mobileText: `Try Visually for Free`,
  mobileMultiplier: 0.7,
} as StrapiTextualProps;

export const ComparisonJoinSectionContent = {
  title: {
    size: `4.8rem`,
    weight: `bold`,
    text: `Ready to start disrupting?`,
    mobileText: `Ready to start disrupting?`,
    mobileMultiplier: 0.6,
    smMobileMultiplier: 0.55,
  } as StrapiTextualProps,
  cta: {
    text: `Join Now`,
  },
  subTitles: [
    {
      text: `Instant setup`,
      size: `2rem`,
      weight: `normal`,
      mobileMultiplier: 0.65,
    } as StrapiTextualProps,
    {
      text: `Free trial`,
      size: `2rem`,
      weight: `normal`,
      mobileMultiplier: 0.65,
    } as StrapiTextualProps,
    {
      text: `Zero commitment`,
      size: `2rem`,
      weight: `normal`,
      mobileMultiplier: 0.65,
    } as StrapiTextualProps,
  ],
};

export const ComparisonReviewsContent = {
  title: {
    size: `4.8rem`,
    weight: `bold`,
    text: `What people are saying`,
  } as StrapiTextualProps,
  subHeader: {
    size: `2.8rem`,
    text: `Some of the most successful product teams in the world use Sketch every day.
    It's also used by hundreds of thousands of individuals, like you. Here's
    what people have to say:`,
    style: {
      fontWeight: `200`,
    },
  } as StrapiTextualProps,
};

export const ComparisonJoinButtons = {
  getVisuallyButton: {
    href: `https://apps.shopify.com/visually-io?utm_source=Website+&utm_medium=HP`,
    text: `Get Visually for free`,
  },
  getExpertButton: {
    href: `/public/talk-to-expert`,
    text: `Talk to an expert`,
  },
};
